/* SHORELIGHT */
export const SHORELIGHT_PRODUCT_IDS = {
    graduate: '30031', // Masters and Doctorate
    others: '30029',
  };
  
  export const SHORELIGHT_SKUS = {
    graduate: '4481', // Masters and Doctorate
    others: '4479',
  };
  
  export const SHORELIGHT_FORMAT = {
    formatType: 'cta',
    formatSubtype: 'hosted-entry',
  };
  
  export const MOBIUS_API_BASE_URL = 'https://api.mobius.highereducation.com';
  