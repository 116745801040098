import * as InternationalBrands from '../constants/internationaBrands';

export const getShorelightUrl = (brandData) => {
    let urlBase: string = "";

    const productId =
        brandData.degree === 'masters' || brandData.degree === 'doctorate'
            ? InternationalBrands.SHORELIGHT_PRODUCT_IDS.graduate
            : InternationalBrands.SHORELIGHT_PRODUCT_IDS.others;
    const sku =
        brandData.degree === 'masters' || brandData.degree === 'doctorate'
            ? InternationalBrands.SHORELIGHT_SKUS.graduate
            : InternationalBrands.SHORELIGHT_SKUS.others;

    urlBase = `${InternationalBrands.MOBIUS_API_BASE_URL}/v1/redirect/${productId}/${sku}?idToken=${brandData.identityToken}`;

    return urlBase;
};