export async function useMonarchRule(ruleId: string) {
  return await new Promise((resolve, reject) => {
    try {
      window.cohesion('monarch:ready', () => {
        window.monarch('rule', ruleId, {}, {}, (err, evaluation) => {
          if (err) return reject(err);
          console.log('Monarch Rule Done', evaluation);
          window.MonarchRules = { [ruleId]: evaluation };
          resolve(evaluation);
        });
      });
    } catch (err) {
      reject(err);
    }
  });
}

export async function useMonarchRuleSet(rulesetId: string) {
  return await new Promise((resolve, reject) => {
    try {
      window.cohesion('monarch:ready', () => {
        window.monarch('ruleset', rulesetId, {}, {}, (err, evaluation) => {
          if (err) return reject(err);
          console.log('Monarch Rule Set Done', evaluation);
          window.MonarchRules = { [rulesetId]: evaluation };
          resolve(evaluation);
        });
      });
    } catch (err) {
      reject(err);
    }
  });
}

export function getMonarchRule(ruleId: string) {
  return window.MonarchRules?.[ruleId];
}